import React from 'react'
import { styled } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Table, TableBody, TableCell, TableRow, Box } from '@material-ui/core'

const ListItemSkeleton = styled(Skeleton)({
    width: '100%',
    margin: '8px',
    height: 20,
    borderRadius: '10px',
})

const SkeletonContainer = styled('div')({
    height: 'max-content',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
})

const TableRowLoader = ({ attributes }) => {
    const loader = (
        <Box width="100%" height="100%">
            <SkeletonContainer>
                <ListItemSkeleton animation="wave" variant="rect" />
            </SkeletonContainer>
        </Box>
    )

    return (
        <TableRow>
            <TableCell colSpan={attributes.length}>{loader}</TableCell>
        </TableRow>
    )
}

const EidTable = ({
    data,
    isLoading,
    LoadingIndicatorComponent = TableRowLoader,
    isLoadingMore,
    noDataMessage = 'No data available',
    attributes,
    pagination,
    TableComponent,
    TableHeadComponent,
    TableRowComponent,
}) => {
    const loadingIndicator = (
        <LoadingIndicatorComponent attributes={attributes} />
    )
    return (
        <>
            <TableComponent>
                <TableHeadComponent attributes={attributes} />
                <TableBody>
                    {isLoading ? (
                        loadingIndicator
                    ) : data.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={attributes.length}>
                                <Box textAlign="center">{noDataMessage}</Box>
                            </TableCell>
                        </TableRow>
                    ) : (
                        data.map((item, index) => (
                            <TableRowComponent
                                key={index}
                                item={item}
                                attributes={attributes}
                            />
                        ))
                    )}
                    {isLoadingMore && loadingIndicator}
                </TableBody>
            </TableComponent>

            <Box display="flex" justifyContent="center">
                {pagination}
            </Box>
        </>
    )
}

export default EidTable
