import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreen } from 'packages/core'
import { ItemDetails } from 'components'
import ApplicationOwner from '../../DynamicComponents/ApplicationOwner'
import moment from 'moment'

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const OverviewSection = ({ secret }) => {
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Application')}
                    value={secret.azureApplicationName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Password')}
                    type={'password'}
                    length={10}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Tenant')}
                    value={secret.azureTenantName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CreatedBy')}
                    value={
                        secret && <ApplicationOwner data={{ item: secret }} />
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CreatedDate')}
                    value="-" // TODO : Enter value from Created Date when back-end property created.
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('ExpirationDate')}
                    value={moment
                        .utc(secret?.endDateTimeUtc)
                        .clone()
                        .local()
                        .format('L LT')}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
