import { useGetControlsAccess } from 'packages/core'

const useSubcomponents = () => {
    const controls = useGetControlsAccess().map((c) => c.name)

    const trimControlsBasedOnAccess = (controlsList) =>
        controlsList.filter((f) =>
            f.requireAccess ? controls.includes(f.requireAccess) : true,
        )

    const canSeeAtLeastOneFilter = (resourceType) =>
        resourceType.filters.filter((f) =>
            f.requireAccess
                ? controls.indexOf(f.requireAccess.control) >= 0
                : true,
        ).length > 0

    return {
        canSeeAtLeastOneFilter,

        trimControlsBasedOnAccess,
    }
}
export default useSubcomponents
