import { default as ConnectSwitch } from './ConnectSwitch'
import { default as DummyPasswordBox } from './DummyPasswordBox'
import { default as DateTimeComponent } from './DateTimeComponent'
import { default as ApplicationOwner } from './ApplicationOwner'
import { default as ApplicationImage } from './ApplicationImage'

const DynamicComponents = {
    ConnectSwitch,
    DummyPasswordBox,
    DateTimeComponent,
    ApplicationOwner,
    ApplicationImage,
}

export default DynamicComponents
