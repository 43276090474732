import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Drawer, Loader } from 'packages/eid-ui'
import { useApplicationSettings, useApplicationSecret } from 'hooks'
import { TextFormatter } from 'components'
import bgHeader from '../../../assets/images/bg.png'
import SecretDetails from './SecretDetails'

const HeaderContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 48px 0px 32px',
    overflow: 'hidden',
    color: '#ffffff',
    fontSize: '22px',
    textAlign: 'center',
})

const SecretsDrawer = ({ id, onClose }) => {
    const { data: secret, isLoading } = useApplicationSecret(id)
    const { data: appConfig } = useApplicationSettings()
    const { t } = useTranslation()


    return (
        <Drawer
            width="768px"
            open={true}
            toggleDrawer={() => onClose()}
            stickyHeader
            header={
                <HeaderContainer
                    height="95px"
                    bgcolor={appConfig.style.colors.primary?.header}
                    style={{
                        backgroundImage: `url(${bgHeader})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain'
                    }}
                >
                    <TextFormatter
                        value={
                            secret?.description ?? secret?.name
                        }
                        maxCharacters={385}
                        style={{textAlign:'start', fontSize:'22px'}}
                    />
                </HeaderContainer>
            }
            closeOnOutsideClick
        >
            {!secret && <Loader />}
            {secret && (
                <SecretDetails
                    data={secret}
                />
            )}
        </Drawer>
    )
}

export default SecretsDrawer
