import { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { Autocomplete, Card, ExpansionPanel } from 'packages/eid-ui'
import { TargetSystemIcon } from 'packages/eid-icons'
import { map, pipe, prop, uniqBy } from 'ramda'
import { usePageContext } from 'pageContext'
import { useIsSmallScreen, useSwrApiGet } from 'packages/core'
import { useTranslation } from 'react-i18next'

const TargetSystemFilter = () => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    const [callEndpoint, setCallEndpoint] = useState(false)
    const [activeType, setActiveType] = useState('')
    const [options, setOptions] = useState([])
    const { data } = useSwrApiGet(
        () => (callEndpoint ? '/api/Applications/targetSystems' : false),
        {
            dedupingInterval: 1800000,
        },
    )

    useEffect(() => {
        if (data) {
            setOptions(data.data)
        }
    }, [data])

    const [state, dispatch] = usePageContext()

    const selectedAccountStoreUsageType = state['accountStoreUsageType'] ?? null
    const accountStore = state['accountStore'] ?? null

    const fetchAccountStoreUsageType = pipe(
        uniqBy(prop('accountStoreUsageTypeId')),
        map((x) => ({
            id: x.accountStoreUsageTypeId,
            friendlyName: x.accountStoreUsageTypeFriendlyName,
        })),
    )

    const fetchAccountStores = () => {
        let filtered = options

        if (selectedAccountStoreUsageType) {
            filtered = options.filter(
                (x) =>
                    x.accountStoreUsageTypeId ===
                    selectedAccountStoreUsageType.id,
            )
        }

        return pipe(
            uniqBy((x) => x.accountStoreId),
            map((x) => ({
                id: x.accountStoreId,
                friendlyName: x.accountStoreFriendlyName,
            })),
        )(filtered)
    }

    const accountStoreUsageTypeActive = activeType === 'accountStoreUsageType'
    const accountStoreActive = activeType === 'accountStore'

    const content = () => (
        <>
            <Box
                padding="0"
                style={{
                    borderTop: 'solid 1px #efeff1',
                    backgroundColor: '#fbfbfd',
                }}
            >
                <Box padding="20px 20px 0px 20px">
                    <Autocomplete
                        label={t('Applications_SelectAccountStoreType')}
                        placeholder={t('Applications_SelectAccountStoreType')}
                        options={fetchAccountStoreUsageType(options)}
                        loading={
                            callEndpoint && !data && accountStoreUsageTypeActive
                        }
                        loadingText=""
                        noOptionsText=""
                        multiple={false}
                        onInputChange={() => {}}
                        onChange={(_, selectedItem) => {
                            dispatch({
                                type: 'SET_MULTIPLE_PROPS',
                                payload: {
                                    accountStoreUsageType: selectedItem,
                                    accountStore: null,
                                },
                            })
                        }}
                        value={selectedAccountStoreUsageType}
                        getOptionLabel={(option) => option.friendlyName}
                        onOpen={() => {
                            setCallEndpoint(true)
                            setActiveType('accountStoreUsageType')
                        }}
                        onClose={() => {
                            setCallEndpoint(false)
                            setActiveType('')
                        }}
                        filterOptions={undefined}
                    />
                </Box>
                <Box padding="20px 20px 0px 20px">
                    <Autocomplete
                        label={t('Applications_SelectAccountStore')}
                        placeholder={t('Applications_SelectAccountStore')}
                        options={fetchAccountStores()}
                        loading={callEndpoint && !data && accountStoreActive}
                        loadingText=""
                        noOptionsText=""
                        multiple={false}
                        onChange={(_, selectedItem) =>
                            dispatch({
                                type: 'SET_PROP',
                                payload: {
                                    key: 'accountStore',
                                    value: selectedItem,
                                },
                            })
                        }
                        value={accountStore}
                        getOptionLabel={(option) => option.friendlyName}
                        onOpen={() => {
                            setCallEndpoint(true)
                            setActiveType('accountStore')
                        }}
                        onClose={() => {
                            setCallEndpoint(false)
                            setActiveType('')
                        }}
                        filterOptions={undefined}
                    />
                </Box>
            </Box>
        </>
    )

    return (
        <Card
            cardTitle={t('Applications_TargetSystem')}
            icon={<TargetSystemIcon color="#959598" />}
            collapsible
            expanded={
                state.accountStore !== null ||
                state.accountStoreUsageType !== null
            }
        >
            {content()}
        </Card>
    )
}

export default TargetSystemFilter
