import { isNilOrEmpty } from 'packages/core'
import config from 'config'

const statusColors = {
    approved: '#1db57a',
    rejected: '#d0021b',
    open: '#d66a00',
}

export const subHeaderHeight = 70
export const smallScreenWidth = '1022px'
export const tabletScreenWidth = '768px'
export const mobileScreenWidth = '767px'

export const getFullImageUrl = (imagePath: any) =>
    !isNilOrEmpty(imagePath) ? `${config.BASE_EID_URL}${imagePath}` : undefined

export const paginate = (items: any, pageNumber: any, pageSize: any) => {
    const startIndex = (pageNumber - 1) * pageSize
    const end = startIndex + pageSize
    items = items.slice(startIndex, end)
    return items
}
export const getStatusColor = (status: any, defaultColor = '#ffffff') => {
    return Object.keys(statusColors).includes(status)
        ? //@ts-ignore
          statusColors[status]
        : defaultColor
}
