import { useApplicationSettings, useApplication } from '../../hooks'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { Box, styled } from '@material-ui/core'
import StickyContainer from 'components/StickyContainer'
import { Button, TextFormatter } from 'components'
import ApplicationDetails from './ApplicationDetails'
import { Drawer } from 'packages/eid-ui'
import bgHeader from '../../assets/images/bgHeader.png'
import React from 'react'

const HeaderContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 48px 0px 32px',
    overflow: 'hidden',
    color: '#ffffff',
    fontSize: '22px',
    textAlign: 'center',
})

const DetailsDrawer = ({ id, onClose }) => {
    const { data: application } = useApplication(id)
    const isSmallScreen = useIsSmallScreen()

    const { data: appConfig } = useApplicationSettings()

    const { t } = useTranslation()

    if (isSmallScreen) {
        return (
            <Box>
                <StickyContainer
                    color="#ffffff"
                    styles={{
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Box paddingY="10px" width="100%" maxWidth="260px">
                            <Button.BackButton onClick={() => onClose()}>
                                {t('BackToApplications')}
                            </Button.BackButton>
                        </Box>

                        <HeaderContainer
                            height="80px"
                            textAlign="start"
                            bgcolor={appConfig.style.colors.primary?.header}
                            style={{
                                backgroundImage: `url(${bgHeader})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            <TextFormatter
                                value={
                                    application?.description ??
                                    application?.name
                                }
                                maxCharacters={75}
                            />
                        </HeaderContainer>
                    </Box>
                </StickyContainer>

                {application && <ApplicationDetails data={application} />}
            </Box>
        )
    } else {
        return (
            <Drawer
                width="1024px"
                open={true}
                toggleDrawer={() => onClose()}
                stickyHeader
                header={
                    <HeaderContainer
                        height="95px"
                        bgcolor={appConfig.style.colors.primary?.header}
                        style={{
                            backgroundImage: `url(${bgHeader})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    >
                        <TextFormatter
                            value={
                                application?.description ?? application?.name
                            }
                            maxCharacters={385}
                        />
                    </HeaderContainer>
                }
                closeOnOutsideClick
            >
                {application && <ApplicationDetails data={application} />}
            </Drawer>
        )
    }
}

export default DetailsDrawer
