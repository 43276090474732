import { FC, useState } from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { useAzureApplicationOwnersAndDeputies } from 'hooks'
import { Skeleton } from '@material-ui/lab'
import { PersonDetails } from './PersonDetails'
import classNames from 'classnames'
import { Pagination } from 'packages/eid-ui'
import { paginate } from 'utils'

export type AzureApplicationOwnersProps = {
    className?: string
    buttonPosition?: 'right' | 'bottom'
    data: any
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: { position: 'relative' },

        buttonPositionRightTop: {},

        content: {
            display: 'flex',
        },
        defaultPerson: {},
        disableDefaultPerson: {
            opacity: 0.4,
        },

        ownersContainer: {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '150px',
            overflow: 'auto',
            padding: '5px 4px',
        },
        collapsibleContent: {
            marginTop : '10px',
            position: 'relative',
            backgroundColor: '#f5f6f7',
            borderRadius: '8px',
            border: 'solid 1px #f9f9f9',
            backdropFilter: 'blur(2px)',
            width : '250px'
        },
        personContainer: {
            padding: '0px 12px',
            borderRadius: '8px',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#ffffff',
                '& p': {
                    color: '#000000',
                    fontWeight: 'bold',
                },
            },
        },
    }),
)

export const AzureApplicationOwners: FC<AzureApplicationOwnersProps> = (
    props,
) => {
    const classes = useStyles()

    const { data: ownersAndDeputies, isLoading } =
        useAzureApplicationOwnersAndDeputies(props.data.id)

    const data = ownersAndDeputies && ownersAndDeputies.owners

    const [page, setPage] = useState(1)

    const pageSize = 5

    const paginatedData = data && paginate(data, page, pageSize)

    const numberOfPages = data ? Math.ceil((data.length) / pageSize) : 0

    const handlePageChange = (_: any, value: any) => {
        setPage(value)
    }

    if (isLoading) {
        return (
            <Box paddingRight={4}>
                <Skeleton height={40} />
            </Box>
        )
    } else {
        if (data && data.length === 0) {
            return <Box paddingLeft={'4px'}>-</Box>
        }

        return (
            <Box className={classNames(classes.root, props.className)}>
                <Box className={classes.collapsibleContent}>
                    <Box className={classes.ownersContainer}>
                        {data &&
                            paginatedData.map((o: any, index: any) => (
                            <Box
                                className={classes.personContainer}
                            >
                                <PersonDetails
                                    key={o.id + index}
                                    size="small"
                                    person={o}
                                />
                            </Box>
                        ))}
                    </Box>
                    {numberOfPages > 1 && (
                    <Box
                        display="flex"
                        alignItems="center"
                        width="100%"
                        justifyContent="center"
                        padding="5px 11px"
                    >
                        <Pagination
                            count={numberOfPages}
                            size="small"
                            siblingCount={0}
                            page={page}
                            onChange={handlePageChange}
                            variant={'rounded'}
                        />
                    </Box>
                    )}
                </Box>
            </Box>
        )
    }
}
