import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { OverviewSection } from './OverviewSection'
import { MoreInformationSection } from './MoreInformationSection'

const SecretDetails = ({ data }) => {
    const { t } = useTranslation()
    const [activeTab, setActiveTab] = useState('overview')

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab value="overview" label={t('Overview')} />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('MoreInformation')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && (
                <OverviewSection secret={data} />
            )}
            {activeTab === 'additionalInformation' && (
                <MoreInformationSection secret={data} />
            )}
        </>
    )
}

export default SecretDetails
