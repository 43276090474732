import { FC, useState } from 'react'
import { Box } from '@material-ui/core'
import { EnterPassword } from './EnterPassword'
import { ResetConfrimation } from './ResetConfrimation'
import { SetPassword } from './SetPassword'
import ItemDetails from 'components/ItemDetails'
import { useTranslation } from 'react-i18next'
import { useAppState } from 'appContext'

export interface PasswordModalProps {
    open?: any
    setOpen?: any
    children?: any
}

export const PasswordModal: FC<PasswordModalProps> = (props) => {
    const { t } = useTranslation()
    const { open, setOpen, children } = props
    const [{ masterPassword }, dispatch]: any = useAppState()

    const defaultContent = (
        <Box minWidth="130px" maxWidth="200px" margin="32px">
            <ItemDetails.ActionButton
                fontColor="#ffffff"
                bgColor={'#307fc1'}
                width="100%"
                onClick={() =>
                    setOpen(masterPassword ? 'addToCart' : 'enterPassword')
                }
            >
                {t('Common_RequestAccess')}
            </ItemDetails.ActionButton>
        </Box>
    )
    return (
        <>
            {children ? children : defaultContent}
            {open === 'enterPassword' && (
                <EnterPassword
                    open={open === 'enterPassword'}
                    onClose={() => setOpen('')}
                    onReset={() => setOpen('resetConfrimation')}
                    onCancel={() => setOpen('')}
                    onSucces={(val: string) => {
                        setOpen('addToCart')
                        dispatch({
                            type: 'SET_IS_MASTER_PASSWORD',
                            payload: val,
                        })
                    }}
                />
            )}

            {open === 'resetConfrimation' && (
                <ResetConfrimation
                    open={open === 'resetConfrimation'}
                    onClose={() => setOpen('')}
                    onReset={() => setOpen('setPassword')}
                    onCancel={() => setOpen('enterPassword')}
                />
            )}

            {open === 'setPassword' && (
                <SetPassword
                    open={open === 'setPassword'}
                    onClose={() => setOpen('')}
                    onCancel={() => setOpen('enterPassword')}
                />
            )}
        </>
    )
}
