import { useApplicationCertificates } from 'hooks'
import { useEidGrid } from 'components'
import { useTranslation } from 'react-i18next'
import certificatesAttributes from './certificatesAttributes'
import {
    CertificatesTable,
    CertificatesTableHead,
    CertificatesRow,
} from './CertificatesTableComponent'

const ApplicationCertificates = ({ azureApplicationId }) => {
    const { t } = useTranslation()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useApplicationCertificates(azureApplicationId)
    const defaultView = 'table'

    const { viewToRender } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('NoCertificatesMessage'),
        pagination,
        attributes: certificatesAttributes,
        tableViewConfig: {
            TableComponent: CertificatesTable,
            TableHeadComponent: CertificatesTableHead,
            TableRowComponent: CertificatesRow,
        },
        defaultView,
    })

    return <>{viewToRender}</>
}

export default ApplicationCertificates
