import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'

export const APPLICATION_SETTINGS_KEY = 'APPLICATION_SETTINGS'
export const ACCESS_SUBCOMPONENTS_KEY = 'ACCESS_SUBCOMPONENTS'
export const ALL_APPLICATIONS_KEY = 'ALL_APPLICATIONS'

export const useApplicationSettings = () => {
    const callApi = useAxios()
    return useQuery(
        APPLICATION_SETTINGS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/configuration/settings',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

export const useAccessSubcomponents = () => {
    const callApi = useAxios()
    return useQuery(
        ACCESS_SUBCOMPONENTS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/configuration/access/subcomponents',
            }),
        {
            staleTime: Infinity,
        },
    )
}

export const useAllApplications = () => {
    const callApi = useAxios()
    return useQuery(
        ALL_APPLICATIONS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/configuration/access/microservices',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}
