const certificatesAttributes = [
    {
        name: 'friendlyName',
        sortable: true,
        sortBy: 'friendlyName',
        label: 'FriendlyName',

        headerCellStyles: {
            width: '150px',
            minWidth: '150px',
            maxWidth: '100px',
        },
        styles: {
            width: '150px',
            maxWidth: '150px',
            color: '#282828',
            fontSize: '13px',
        },
    },

    {
        name: 'password',
        sortable: false,
        sortBy: 'password',
        hideValue: true,
        label: 'Password',
        component: {
            componentName: 'DummyPasswordBox',
            length: 10,
            locked: true,
        },

        headerCellStyles: {
            width: '80px',
            minWidth: '80px',
            maxWidth: '100px',
        },
        styles: {
            width: '80px',
            minWidth: '80px',
            maxWidth: '100px',
            fontSize: '13px',
            color: '#282828',
        },
    },
    {
        name: 'ownerFriendlyName',
        sortable: true,
        hideValue: true,
        sortBy: 'ownerFriendlyName',
        label: 'Owner',
        component: {
            componentName: 'ApplicationOwner',
        },
        headerCellStyles: {
            width: '130px',
            minWidth: '130px',
            maxWidth: '140px',
            fontSize: '13px',
        },
        styles: {
            minWidth: '130px',
            maxWidth: '140px',
            width: '130px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        label: 'CheckIn',
        hideValue: true,
        headerCellStyles: {
            width: '60px',
            minWidth: '60px',
            maxWidth: '80px',
            fontSize: '13px',
        },
        component: {
            componentName: 'checkInButton',
            name: 'checkInButton',
        },
        styles: {
            width: '60px',
            minWidth: '60px',
            maxWidth: '90px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        hideValue: true,
        label: 'Status',

        headerCellStyles: {
            width: '100px',
            minWidth: '100px',
            maxWidth: '100px',
            fontSize: '13px',
        },
        component: {
            componentName: 'checkOutButton',
            name: 'checkOutButton',
        },
        styles: {
            width: '100px',
            minWidth: '100px',
            maxWidth: '120px',
        },
    },
    {
        name: 'endDateTimeUtc',
        sortable: true,
        sortBy: 'endDateTimeUtc',
        label: 'EndDate',
        hideValue: true,
        headerCellStyles: {
            width: '170px',
            minWidth: '170px',
            maxWidth: '170px',
            fontSize: '13px',
        },
        component: {
            componentName: 'DateTimeComponent',
            name: 'dateTime',
        },
        styles: {
            width: '170px',
            minWidth: '170px',
            maxWidth: '170px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        hideValue: true,
        component: {
            componentName: 'actionButtons',
            name: 'ActionButtons',
        },
        styles: {
            width: '200px',
            minWidth: '200px',
            maxWidth: '200px',
        },

        headerCellStyles: {
            width: '200px',
            minWidth: '200px',
            maxWidth: '200px',
        },
    },
]

export default certificatesAttributes
