import { styled, Box } from '@material-ui/core'
import { useTranslation } from "react-i18next"
import { useApplicationSecrets} from "hooks"
import { useEidGrid } from "components"
import { SecretRow, SecretsTable, SecretsTableHead } from "./SecretsTableComponent"
import secretsAttributes from "./secretsAttributes"


export const ListingGrid = styled(Box)({
    width: '100%',
})


const ApplicationSecrets = ({azureApplicationId
}) => {
    const {t} = useTranslation()
    const defaultView = 'table'

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useApplicationSecrets(azureApplicationId)


    const {viewToRender } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('NoSecretsMessage'),
        pagination,
        attributes : secretsAttributes,
        tableViewConfig: {
            TableComponent: SecretsTable,
            TableHeadComponent: SecretsTableHead,
            TableRowComponent: SecretRow,
        },
        defaultView,
    })

    
    return(
<>{viewToRender}</>
)

}

export default ApplicationSecrets