import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { InputBase, Box, Button as MuiButton, Popover } from '@material-ui/core'
import { useAppState } from 'appContext'
import { Tooltip } from 'packages/eid-ui'
import { PasswordModal } from '../../components/PasswordModal'
const useStyles = makeStyles((theme) => ({
    input: {
        padding: 0,
        color: 'black !important',
    },
}))
const DummyPasswordBox = ({ attribute, data }) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const [{ masterPassword }, dispatch] = useAppState()
    const [masterPasswordOpen, setMasterPasswordOpen] = useState('')
    const [fetchedValue, setFetchedValue] = useState()
    const [isRevealed, setIsRevealed] = useState(false)
    if (!masterPassword && attribute.component.locked) {
        return (
            <PasswordModal
                open={masterPasswordOpen}
                setOpen={setMasterPasswordOpen}
            >
                <MuiButton
                    size="small"
                    variant="outlined"
                    onClick={() =>
                        setMasterPasswordOpen(
                            masterPassword ? 'addToCart' : 'enterPassword',
                        )
                    }
                >
                    {'Unlock'}
                </MuiButton>
            </PasswordModal>
        )
    }

    if (!masterPassword) {
        return (
            <InputBase
                value={'*'.repeat(
                    attribute.component.length ? attribute.component.length : 5,
                )}
                type={'password'}
                classes={{ input: classes.input }}
                disabled
            />
        )
    }

    if (data.alreadyCheckedOut) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center">
                <Tooltip
                    placement="top-start"
                    title={'You should checkout before taking any action'}
                    interactive
                >
                    <InputBase
                        value={'*'.repeat(
                            attribute.component.length
                                ? attribute.component.length
                                : 5,
                        )}
                        type={'password'}
                        classes={{ input: classes.input }}
                        disabled
                    />
                </Tooltip>
            </Box>
        )
    }

    const showPopover = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const hidePopover = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)

    const popoverId = open ? data?.resource?.id + attribute.name : undefined

    const handleCopy = () => {
        let apiResponseValue = 'copied'
        navigator.clipboard.writeText(apiResponseValue)
        hidePopover()
        alert('Copied to clipboard')
    }

    const toogleReveal = () => {
        let apiResponseValue = 'revealed'
        hidePopover()
        setTimeout(() => {
            setIsRevealed(!isRevealed)
        }, [100])
        setFetchedValue(apiResponseValue)
    }

    if (!data.alreadyCheckedOut) {
        return (
            <Box
                display="flex"
                style={{ cursor: 'pointer' }}
                onMouseEnter={showPopover}
                onMouseLeave={hidePopover}
            >
                {isRevealed && fetchedValue
                    ? fetchedValue
                    : '*'.repeat(
                          attribute.component.length
                              ? attribute.component.length
                              : 5,
                      )}
                <Popover
                    id={popoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={hidePopover}
                    onMouseLeave={hidePopover}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    disableRestoreFocus
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                >
                    <div
                        style={{ background: '#262fa1' }}
                        onMouseLeave={hidePopover}
                    >
                        <MuiButton
                            style={{ color: 'white' }}
                            size="small"
                            variant="text"
                            onClick={handleCopy}
                        >
                            Copy
                        </MuiButton>
                        <MuiButton
                            style={{ color: 'white' }}
                            size="small"
                            variant="text"
                            onClick={toogleReveal}
                        >
                            {isRevealed ? 'Hide' : 'Reveal'}
                        </MuiButton>
                    </div>
                </Popover>
            </Box>
        )
    }
}
export default DummyPasswordBox
