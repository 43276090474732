/* eslint-disable default-case, no-param-reassign */
export const initialState = {
    masterPassword: sessionStorage.getItem('MasterPassword'),
}
const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'SET_MULTIPLE_PROPS':
            return {
                ...state,
                ...payload,
            }

        case 'SET_PROP':
            return {
                ...state,
                [payload.key]: payload.value,
            }
        case 'SET_IS_MASTER_PASSWORD':
            sessionStorage.setItem('MasterPassword', payload)
            return {
                ...state,
                masterPassword: payload,
            }
        default:
            return state
    }
}

export default reducer
