import React, { useEffect } from 'react'
import { useQuery, isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Paper } from '@material-ui/core'
import { useApplicationWorkflows } from 'hooks/applicationsHooks'
import { Loader, WorkflowIFrame } from 'packages/eid-ui'
import { useHistory, useParams } from 'react-router'
import { usePageContext } from 'pageContext'
import { MobileStickyContentListing, Button } from 'components'
import StickyContainer from 'components/StickyContainer'

import config from 'config'
import WorkFlowsItems from 'containers/Workflows/WorkflowItems.tsx'

const Workflows: React.FC<any> = () => {
    const [state, dispatch] = usePageContext() as any
    const { t } = useTranslation()
    const query = useQuery()
    const workflowName = query.get('workflowName')
    const resourceID = query.get('resourceID')
    const history = useHistory()

    const { data, isLoading } = useApplicationWorkflows()
    useEffect(() => {
        if (data) {
            dispatch({
                type: 'SET_PROP',
                payload: {
                    key: 'workflowsCount',
                    value: data.length,
                },
            })
        }
    }, [data])
    const { applicationId } = useParams<any>()
    const isSmallScreen = useIsSmallScreen()

    const workflowIFrameHeight = isNilOrEmpty(applicationId) ? '82vh' : '62vh'
    const wfParams: any = []
    if (resourceID) {
        wfParams.push(`SelectedResourceID=${resourceID}`)
    }
    const cardStyles = {
        borderRadius: '5px',
        height: '55px',
        outerWidth: '100px',
    }

    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1]
    const routeName = pathname.split('/')[2]

    const MobileSubHeader = (
        <>
            <MobileStickyContentListing
                totalCount={state.workflowsCount}
                title={areaName}
                subTitle={routeName}
                showSearch={false}
                handleApplyFilters={''}
            />
        </>
    )

    const MobileHeaderButton = (
        <StickyContainer
            color="#ffffff"
            styles={{
                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
            }}
        >
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <Box paddingY="10px" width="100%" maxWidth="260px">
                    <Button.BackButton
                        onClick={() => {
                            query.delete('workflowName')
                            history.push(
                                history.location.pathname,
                                query.toString(),
                            )
                        }}
                    >
                        {t('BackToWorkflows')}
                    </Button.BackButton>
                </Box>
            </Box>
        </StickyContainer>
    )

    if (workflowName) {
        return (
            <>
                {isSmallScreen ? MobileHeaderButton : <></>}
                <Box minWidth={'100%'}>
                    <WorkflowIFrame
                        baseEidUrl={config.BASE_EID_URL}
                        workflowName={workflowName}
                        onComplete={() => {
                            query.delete('workflowName')
                            history.push(
                                history.location.pathname,
                                query.toString(),
                            )
                        }}
                        workflowParams={wfParams}
                        height={workflowIFrameHeight}
                    />
                </Box>
            </>
        )
    }
    return (
        <>
            {isSmallScreen ? MobileSubHeader : <></>}
            <div
                style={{
                    width: '100%',

                    paddingBottom: '25px',
                }}
            >
                <Paper className={'root workflow'}>
                    <Grid
                        container
                        style={{ justifyContent: 'start', gap: '24px' }}
                    >
                        {isLoading ? (
                            <div style={{ alignSelf: 'center' }}>
                                <Loader />
                            </div>
                        ) : (
                            data.map((w: any, index: number) => (
                                <WorkFlowsItems
                                    onClick={() => {
                                        w.requestWorkflowName
                                            ? history.push(
                                                  `${history.location.pathname}?workflowName=${w.requestWorkflowName}`,
                                              )
                                            : history.push(
                                                  `${history.location.pathname}`,
                                              )
                                    }}
                                    key={`work-flow-${index}`}
                                    title={w.friendlyName}
                                    description={w.description}
                                    isEnabled={w.requestWorkflowName}
                                />
                            ))
                        )}
                    </Grid>
                </Paper>
            </div>
        </>
    )
}
export default Workflows
