import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'

export const useUiActionsByResource = (resourceId, isEnabled = true) => {
    const callApi = useAxios()
    return useQuery(
        ['UI_ACTIONS', resourceId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/uiActions/byResourceId?resourceId=${resourceId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(resourceId) && isEnabled,
        },
    )
}
