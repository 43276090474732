import React, { Fragment, useEffect } from 'react'
import { Collapse, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import attributes from './attributes'
import ApplicationCard from './ApplicationCard'
import { Card } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import {
    useIsSmallScreen,
    useQuery,
    useSessionStorage,
    useGetControlsAccess,
} from 'packages/core'
import { EidWorkflowButton } from 'components/EidWorkflowButton'
import { useHistory } from 'react-router'
import StickyContainer from 'components/StickyContainer'
import DetailsDrawer from './DetailsDrawer'
import {
    ApplicationsTable,
    ApplicationsTableHead,
    ApplicationRow,
} from './ApplicationsTableComponents'
import {
    AppLayout,
    useEidGrid,
    CardViewLoader,
    MobileStickyContentListing,
    MobileFiltersScreen,
    Filters,
    DesktopContainer,
    GridContainer,
    FiltersColumn,
    FilterContainer,
    ListingGrid,
    styles,
    SmallScreenContainer,
} from 'components'

import { useApplication, useApplicationSettings } from 'hooks'
import { ApplicationBanner } from 'components/ApplicationBanner'
import ReactHtmlParser from 'react-html-parser'
import DomPurify from 'dompurify'
import { usePageContext } from 'pageContext'
import { useOwnedApplications } from 'hooks'

const ApplicationsLayout = ({
    filters,
    // resourceTypesToShow,
}) => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()

    const {
        list,
        totalCount,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
        refetch,
        rest,
    } = useOwnedApplications()

    const [openFilters, setOpenFilters] = useSessionStorage(
        'FILTERS_EXPANDED',
        true,
    )

    useEffect(() => {
        if (rest && state.shouldLoadTags) {
            dispatch({
                type: 'SET_TAGS',
                payload: rest.tags,
            })
        }
    }, [rest])

    const isSmallScreen = useIsSmallScreen()

    const defaultView = isSmallScreen ? 'card' : 'table'

    const { viewSwitcher, setCurrentView, viewToRender } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('No_Applications_Message'),
        pagination,
        attributes,
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: ApplicationCard,
        },
        tableViewConfig: {
            TableComponent: ApplicationsTable,
            TableHeadComponent: ApplicationsTableHead,
            TableRowComponent: ApplicationRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView('card')
        }
    }, [isSmallScreen])

    const query = useQuery()
    const controls = useGetControlsAccess()

    const hasAccessToCreateAzureApplicationWorkflow =
        controls.findIndex(
            (c) =>
                c.name ===
                'ResourceAdmin-CreateAzureApplicationWorkflow-Control',
        ) >= 0
    const hasAccessToParsedHtmlMoreInfo =
        controls.findIndex(
            (c) => c.name === 'ResourceAdmin-ParsedHtmlMoreInformation-Control',
        ) >= 0
    const id = query.get('id')
    const workflowName = query.get('workflow')
    const applicationId = query.get('applicationId')
    const { data: appConfig } = useApplicationSettings()
    const { staticWorkflows } = appConfig
    const onboardAzureApplicationWF =
        staticWorkflows && staticWorkflows.length > 0
            ? staticWorkflows.filter(
                  (w) => w.name === 'OnboardAzureApplication',
              )
            : []
    const { data: application } = useApplication(applicationId)

    const wfParams = []
    if (application) {
        wfParams.push(`SelectedResourceID=${application.resourceID}`)
    }

    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1]
    const routeName = pathname.split('/')[2]

    const showFilters = query.get('filters') === 'visible'
    if (application) {
        if (isSmallScreen) {
            return (
                <AppLayout
                    totalCount={totalCount}
                    showBackButton={Boolean(application)}
                >
                    <DetailsDrawer
                        id={applicationId}
                        onClose={() => {
                            history.push(history.location.pathname)
                            refetch()
                        }}
                    />
                </AppLayout>
            )
        }
        return (
            <AppLayout
                totalCount={totalCount}
                showNavTabs={!applicationId}
                showBackButton={Boolean(application)}
            >
                <DesktopContainer>
                    {application && (
                        <Box paddingX="8px" marginBottom="16px" width="100%">
                            <ApplicationBanner applicationId={applicationId} />
                        </Box>
                    )}
                </DesktopContainer>
            </AppLayout>
        )
    }

    const mobileFiltersScreen = (
        <MobileFiltersScreen
            totalCount={totalCount}
            filters={filters}
            handleApplyFilters={() => {
                query.delete('filters')
                history.push(`${history.location.pathname}?${query.toString()}`)
            }}
        />
    )
    const mobileStickyContentListing = (
        <>
            <MobileStickyContentListing
                totalCount={totalCount}
                title={areaName}
                subTitle={routeName}
                handleApplyFilters={() => {
                    query.set('filters', 'visible')
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
            />
        </>
    )

    const parsedHtmlMoreInfo = ReactHtmlParser(
        DomPurify.sanitize(t('MoreInfo_Html'), { ADD_ATTR: ['target'] }),
    )

    let content
    if (isSmallScreen) {
        content = (
            <SmallScreenContainer>
                <Box display={showFilters ? '' : 'none'}>
                    {mobileFiltersScreen}
                </Box>

                <Box display={!showFilters ? '' : 'none'}>
                    <Box display={!id ? '' : 'none'}>
                        <StickyContainer>
                            <Box width="100%">{mobileStickyContentListing}</Box>
                        </StickyContainer>
                        {viewToRender}
                    </Box>
                </Box>
            </SmallScreenContainer>
        )
    } else {
        content = (
            <DesktopContainer>
                {applicationId ? (
                    <Box paddingX="8px" marginBottom="16px" width="100%">
                        <ApplicationBanner applicationId={applicationId} />
                    </Box>
                ) : (
                    <Fragment>
                        {/* <h2>Page Context :{JSON.stringify(state)}</h2> */}
                        <FiltersColumn>
                            <FilterContainer>
                                <Box padding="16px 0px 0px 0px" display="flex">
                                    <Box width="100%" padding="0px 5px">
                                        <Card.CardHeader
                                            collapsible
                                            fontColor="black"
                                            cardTitle={t('Show_My_Filters')}
                                            handleExpandClick={() =>
                                                setOpenFilters((prev) => !prev)
                                            }
                                            expanded={openFilters}
                                            icon={
                                                <Icon
                                                    name="AdvancedSearch"
                                                    color="#959598"
                                                />
                                            }
                                            style={styles.filterCardHeader}
                                        />
                                    </Box>
                                </Box>
                            </FilterContainer>

                            <Collapse
                                in={openFilters}
                                timeout={300}
                                unmountOnExit
                            >
                                {!applicationId &&
                                    hasAccessToCreateAzureApplicationWorkflow &&
                                    onboardAzureApplicationWF.length > 0 && (
                                        <Box>
                                            <EidWorkflowButton
                                                title={t(
                                                    onboardAzureApplicationWF[0]
                                                        .displayName_LocaleKey,
                                                )}
                                                name={
                                                    onboardAzureApplicationWF[0]
                                                        .requestWorkflowName
                                                }
                                                path={'/applications/workflows'}
                                                disabled={workflowName}
                                            />
                                        </Box>
                                    )}
                                {hasAccessToParsedHtmlMoreInfo && (
                                    <Box style={{ margin: '15px 0px' }}>
                                        <Card
                                            cardTitle={t('More_Information')}
                                            icon={<Icon name="MoreInfo" />}
                                            collapsible
                                            expanded
                                            disabled={applicationId}
                                        >
                                            <Box
                                                padding="0px 10px"
                                                maxWidth="350px"
                                                overflow="scroll"
                                            >
                                                {parsedHtmlMoreInfo}
                                            </Box>
                                        </Card>
                                    </Box>
                                )}
                                <>
                                    {filters.map((f) => {
                                        const FilterToRender =
                                            Filters.filtersMap[f]
                                        return (
                                            <FilterContainer key={f}>
                                                <FilterToRender />
                                            </FilterContainer>
                                        )
                                    })}
                                </>
                            </Collapse>
                        </FiltersColumn>

                        <ListingGrid>
                            <Box padding="16px 0px 0px 0px" display="flex">
                                <Box width="100%">
                                    <Filters.TextSearch />
                                </Box>
                                {/* More Filters Here */}
                            </Box>
                            <GridContainer openFilters={openFilters}>
                                {viewToRender}
                            </GridContainer>
                        </ListingGrid>
                    </Fragment>
                )}
            </DesktopContainer>
        )
    }

    return (
        <AppLayout
            totalCount={totalCount}
            viewSwitcher={viewSwitcher}
            showNavTabs={true}
        >
            <Fragment>
                {id && (
                    <DetailsDrawer
                        id={id}
                        onClose={() => {
                            history.push(history.location.pathname)
                            refetch()
                        }}
                    />
                )}

                {content}
            </Fragment>
        </AppLayout>
    )
}

export default ApplicationsLayout
