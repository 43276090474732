import { Fragment, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { requireAuth, useQuery, useAuthState } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import withRequiredData from './withRequiredData'
import { PageContextProvider } from 'pageContext'
import { RequestViewAllApplications } from 'containers/Applications'
import Workflows from 'containers/Workflows'
import { AppLayout } from 'components'
import { setIsWorkflowRenderable } from 'packages/core/auth/actions'
import config from 'config'

const App = () => {
    const [{ isWorkflowIRenderable }, authDispatch] = useAuthState()

    const query = useQuery()
    const workflowName = query.get('workflowName')
    useEffect(() => {
        if (!isWorkflowIRenderable) {
            setTimeout(() => {
                authDispatch(setIsWorkflowRenderable(true))
            }, 30000)
        }
    }, [])

    return (
        <Fragment>
            {!isWorkflowIRenderable && (
                <div style={{ display: 'none' }}>
                    <iframe
                        title={`EmpowerID`}
                        src={`${config.BASE_EID_URL}/ui`}
                    />
                </div>
            )}

            <Switch>
                <Route
                    exact
                    path="/applications/workflows"
                    render={(props) => (
                        <PageContextProvider key="applications/workflows">
                            <AppLayout
                                showNavTabs
                                showBackButton={workflowName}
                            >
                                <Workflows />
                            </AppLayout>
                        </PageContextProvider>
                    )}
                />

                <Route
                    exact
                    path="/applications"
                    render={(props) => (
                        <PageContextProvider key="RequestViewApplications">
                            <RequestViewAllApplications {...props} />
                        </PageContextProvider>
                    )}
                />

                <Route exact path="/">
                    <Redirect to="/applications" />
                </Route>

                <Redirect from="" to="/notfound" />
            </Switch>
        </Fragment>
    )
}

export default requireAuth(withRequiredData(App), <Loader />)
