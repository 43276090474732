import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreen } from 'packages/core'
import { useApplicationOwners } from 'hooks'
import { ItemDetails, PeopleList } from 'components'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const MoreInformationSection = ({ secret }) => {
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { data: owners, isLoading: ownersLoading } = useApplicationOwners(
        secret.id,
    )

    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <ItemDetails.AttributesContainer bottomPadding="0">
                    <PaddedDiv>
                        <ItemDetails.Attribute
                            label={t('Owners')}
                            value={<PeopleList list={owners} />}
                            orientation={attributeOrientation}
                        />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            )}
        </>
    )
}
