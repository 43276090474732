import React, { useState } from 'react'
import { Loader } from 'packages/eid-ui'
import {
    MenuItem,
    makeStyles,
    ListItemText,
    ListItemIcon,
    Paper,
    Typography,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { useQuery } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { useUiActionsByResource } from 'hooks'

const useMenuStyles = makeStyles({
    root: {
        fontSize: '16px',
        position: 'relative',
        minHeight: '40px',
        paddingLeft: '30px',
        borderLeft: '2px solid transparent',
        '&:hover': {
            backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
            borderLeft: '2px solid #307fc1',
            '& svg': {
                opacity: 1,
                filter: 'grayscale(0%) ',
                color: 'blue',
            },
        },
    },
    icon: {
        minWidth: '40px',
        color: 'black',
        '& svg': {
            height: '16px',
            width: '16px',
        },
    },
})

const useHeaderStyles = makeStyles({
    root: {
        fontSize: '16px',
        position: 'relative',
        minHeight: '40px',
        paddingLeft: '30px',
        borderLeft: '2px solid transparent',
        borderRadius: '4px',
    },
    icon: {
        minWidth: '40px',
        color: 'black',
        '& svg': {
            height: '16px',
            width: '16px',
        },
    },
    selected: {
        backgroundColor: '#307fc1 !important',
        color: '#fff',
    },
})

const ActionsList = ({ application }) => {
    const classes = useMenuStyles()

    const { data, isLoading } = useUiActionsByResource(application.id)
    const query = useQuery()
    const history = useHistory()

    const handleClick = (name) => {
        query.set('workflowName', name)
        query.set('resourceID', `${application.resourceID}`)
        history.push(`/applications/workflows?${query.toString()}`)
    }

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Paper style={{ borderRadius: '8px' }}>
                    {data &&
                        data.map((d) => (
                            <MenuItem
                                onClick={() =>
                                    handleClick(d.requestWorkflowName)
                                }
                                classes={classes}
                            >
                                <ListItemIcon className={classes.icon}>
                                    <Icon name="Arrow" direction="right" />
                                </ListItemIcon>
                                <ListItemText>{d.friendlyName}</ListItemText>
                            </MenuItem>
                        ))}
                </Paper>
            )}
        </>
    )
}

const ActionsNew = ({ application }) => {
    const classes = useHeaderStyles()
    const [open, setOpen] = useState(false)
    const header = (
        <Paper style={{ marginBottom: '5px' }}>
            <MenuItem
                classes={classes}
                selected={open}
                onClick={() => {
                    setOpen((prev) => !prev)
                }}
            >
                <ListItemIcon className={classes.icon}>
                    <Icon name="Actions" color={open ? 'white' : 'black'} />
                </ListItemIcon>
                <ListItemText>Actions</ListItemText>

                <Typography style={{ display: 'inherit' }}>
                    <Icon
                        name="Arrow"
                        direction="down"
                        color={open ? 'white' : 'black'}
                    />
                </Typography>
            </MenuItem>
        </Paper>
    )

    return (
        <>
            {header}
            {open && (
                <>
                    <ActionsList application={application}></ActionsList>
                </>
            )}
        </>
    )
}

export default ActionsNew
