import { useApplicationSettings, useApplicationCertificate } from 'hooks'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { Box, styled } from '@material-ui/core'
import StickyContainer from 'components/StickyContainer'
import { Button, TextFormatter } from 'components'
import { Drawer, Loader } from 'packages/eid-ui'
import bgHeader from '../../../assets/images/bgHeader.png'
import React from 'react'
import CertificateDetails from './CertificateDetails'

const HeaderContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 48px 0px 32px',
    overflow: 'hidden',
    color: '#ffffff',
    fontSize: '22px',
    textAlign: 'center',
})

const CertificatesDrawer = ({ id, onClose }) => {
    const { data: certificate } = useApplicationCertificate(id)
    const { data: appConfig } = useApplicationSettings()
    const { t } = useTranslation()
    return (
        <Drawer
            width="1024px"
            open={true}
            toggleDrawer={() => onClose()}
            stickyHeader
            header={
                <HeaderContainer
                    height="95px"
                    bgcolor={appConfig.style.colors.primary?.header}
                    style={{
                        backgroundImage: `url(${bgHeader})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                    }}
                >
                    <TextFormatter
                        value={certificate?.description ?? certificate?.name}
                        maxCharacters={385}
                        style={{ textAlign: 'start', fontSize: '22px' }}
                    />
                </HeaderContainer>
            }
            closeOnOutsideClick
        >
            {!certificate && <Loader />}
            {certificate && <CertificateDetails data={certificate} />}
        </Drawer>
    )
}

export default CertificatesDrawer
