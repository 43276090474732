import { useTranslation } from 'react-i18next'
import { usePageContext } from 'pageContext'
import { isNilOrEmpty, useAuthState } from 'packages/core'
import Search from './Search'
import DateTimeFilter from './DateTimeFilter'
import PersonFilter from './PersonFilter'
import TargetSystemFilter from './TargetSystemFilter'
import { Card } from 'packages/eid-ui'
import AdvancedFilter from './EidFilter/AdvancedFilter'
import { Icon } from 'packages/eid-icons'
import { Box } from '@material-ui/core'
import ShowAzureAppsFilter from './ShowOnlyAzureAppsFilter'
const TextSearchFilter = () => {
    const [state, dispatch] = usePageContext()

    return (
        <Search
            handleSearch={(searchKey) =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'searchTerm',
                        value: searchKey,
                    },
                })
            }
            handleClear={(searchKey) =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'searchTerm',
                        value: searchKey,
                    },
                })
            }
            value={state.searchTerm}
        />
    )
}

const StartDateFilter = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()

    return (
        <DateTimeFilter
            disableFuture
            showLabelWithin
            placeholder={t('MyTasks_BetweenDate')}
            value={
                isNilOrEmpty(state.startDate)
                    ? null
                    : state.startDate.clone().local()
            }
            onChange={(date) => {
                const dateToSet = date.clone()
                dateToSet.startOf('day')
                dateToSet.utc()

                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'startDate',
                        value: dateToSet,
                    },
                })
            }}
            handleClear={() =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'startDate',
                        value: null,
                    },
                })
            }
        />
    )
}

const EndDateFilter = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()

    return (
        <DateTimeFilter
            disableFuture
            showLabelWithin
            placeholder={t('MyTasks_AndDate')}
            value={
                isNilOrEmpty(state.endDate)
                    ? null
                    : state.endDate.clone().local()
            }
            onChange={(date) => {
                const dateToSet = date.clone().endOf('day')
                dateToSet.utc()

                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'endDate',
                        value: dateToSet,
                    },
                })
            }}
            handleClear={() =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'endDate',
                        value: null,
                    },
                })
            }
        />
    )
}

const dateFilterStyles = {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '15px',
    boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #ebebed',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    color: '#7D7C7C',
}

const DateFilter = ({ label }) => {
    return (
        <Box style={dateFilterStyles}>
            <Box minWidth="120px">{label}</Box>
            <StartDateFilter />
            <EndDateFilter />
        </Box>
    )
}

const OwnedByFilter = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()
    const [{ currentUserId, friendlyName }] = useAuthState()

    return (
        <Card
            cardTitle={t('OwnedBy')}
            collapsible
            expanded={state.ownedBy !== null}
        >
            <PersonFilter
                value={state.ownedBy}
                onClear={() =>
                    dispatch({
                        type: 'SET_PROP',
                        payload: {
                            key: 'ownedBy',
                            value: {
                                id: currentUserId,
                                friendlyName: friendlyName,
                            },
                        },
                    })
                }
                onChange={(payload) => {
                    dispatch({
                        type: 'SET_PROP',
                        payload: {
                            key: 'ownedBy',
                            value: payload,
                        },
                    })
                }}
            />
        </Card>
    )
}

const ApplicationsAdvancedSearchFilters = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()

    const applicationsAdvancedSearchforms = {
        title: 'Common_Forms',
        colorCode: {
            primary: '#2920ad',
            secondary: '#ebeaf7',
        },
        filters: [
            {
                name: 'friendlyName',
                type: 'text',
                label: 'Common_Name',
                placeholder: 'Common_Name',
                contextProp: 'advancedSearch.forms.friendlyName',
            },
            {
                name: 'description',
                type: 'text',
                label: 'Common_Description',
                placeholder: 'Common_Description',
                contextProp: 'advancedSearch.forms.description',
            },
        ],
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearchTags',
        },
    }

    return (
        <Card
            cardTitle={t('Common_AdvancedSearch')}
            collapsible
            icon={<Icon name="AdvancedSearch" color="#959598" />}
            expanded={
                state['advancedSearch.forms.friendlyName'] ||
                state['advancedSearch.forms.description'] ||
                state['advancedSearchTags']
            }
        >
            <Box padding="20px 20px 0px 20px ">
                <AdvancedFilter
                    forms={applicationsAdvancedSearchforms}
                    tags={applicationsAdvancedSearchforms.tags}
                />
            </Box>
        </Card>
    )
}

const Filters = () => <></>

Filters.TextSearch = TextSearchFilter
Filters.StartDate = StartDateFilter
Filters.EndDate = EndDateFilter
Filters.DateFilter = DateFilter
Filters.TargetSystemFilter = TargetSystemFilter
Filters.OwnedByFilter = OwnedByFilter
Filters.ApplicationsAdvancedSearchFilters = ApplicationsAdvancedSearchFilters
Filters.ShowAzureAppsFilter=ShowAzureAppsFilter

const filtersMap = {
    applicationsOwnedByFilter: OwnedByFilter,
    showOnlyAzureApplications:ShowAzureAppsFilter,
    targetSystemFilter: TargetSystemFilter,
    applicationsAdvancedSearch: ApplicationsAdvancedSearchFilters,
}

Filters.filtersMap = filtersMap

export default Filters
