import useSubcomponents from 'useSubcomponents'

export const useRouteOptions = () => {
    // const {
    //     hasAccessToRequestViewMyRequestsPage,
    //     hasAccessToItemViewMyRequestsPage,
    //     hasAccessToRequestViewMyTasksPage,
    //     hasAccessToItemViewMyTasksPage,
    //     hasAccessToRequestViewAllRequestsPage,
    //     hasAccessToItemViewAllRequestsPage,
    // } = useSubcomponents()

    const routeOptions = []
    // if (
    //     hasAccessToRequestViewMyRequestsPage ||
    //     hasAccessToItemViewMyRequestsPage
    // ) {
    routeOptions.push({
        name: 'Applications',
        title: 'Applications',
        icon: 'MTMyRequests',
        route: '/applications',

        children: [
            {
                title: 'View All',
                icon: 'BusinessRolesSmall',
                route: '/applications',
            },
            {
                title: 'WorkFlows',
                icon: 'ManagementRolesSmall',
                route: '/applications/workflows',
            },
        ],
    })
    // }

    return { routeOptions }
}
