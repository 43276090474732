import { useEffect, useState } from 'react'
import { useQuery } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import { useAnonymousTranslations } from 'hooks'
import moment from 'moment'
import i18n from 'i18next'
import config from 'config'

const useLoadTranslations = () => {
    const RESOURCE_SET_NAME = 'ResourceAdmin'

    const query = useQuery()
    const debugLocalization = query.get('debugLocalization') === 'true'

    const { data } = useAnonymousTranslations()

    const locale = config.FALLBACK_LOCALE

    useEffect(() => {
        i18n.changeLanguage(locale)
        moment.locale(locale)
    }, [locale])

    const [resourceBundle, setResourceBundle] = useState(null)
    useEffect(() => {
        if (data) {
            const localeData = data.reduce((current, r) => {
                let keyToUse = r.key
                const parts = r.key.split('_')
                if (parts[0] === RESOURCE_SET_NAME) {
                    keyToUse = r.key.substring(RESOURCE_SET_NAME.length + 1)
                }

                if (debugLocalization) {
                    current[keyToUse] = r.key
                } else {
                    current[keyToUse] = r.translatedValue
                }

                return current
            }, {})

            i18n.addResourceBundle(locale, 'public', localeData)
            setResourceBundle(localeData)
        }
    }, [data, locale, debugLocalization])

    return Boolean(resourceBundle)
}

const withRequiredLocaleData = (ChildComponent) => (props) => {
    const translationsLoaded = useLoadTranslations()

    if (!translationsLoaded) {
        return <Loader />
    }

    return <ChildComponent {...props} />
}

export default withRequiredLocaleData
