import { useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box, Paper, styled, Tab } from '@material-ui/core'
import { useApplication } from 'hooks'
import { getFullImageUrl } from 'utils'
import { isNilOrEmpty, useQuery } from 'packages/core'
import { Overview } from './Overview/Overview'
import ApplicationTabs from './ApplicationTabs'
import ActionsNew from './ActionsNew'
import ApplicationCertificates from 'components/ApplicationBanner/Application Certificates/ApplicationCertificates'
import ApplicationSecrets from './Application Secrets/ApplicationSecrets'
import SecretsDrawer from './Application Secrets/SecretsDrawer'
import { useHistory } from 'react-router'
import CertificatesDrawer from './Application Certificates/CertificatesDrawer'

const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            display: 'flex',
            height: '100%',
            justifyContent: 'space-between',
            width: '100%',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        imgContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            width: '100%',
            minHeight: '125px',
            borderRadius: '8px',
            marginBottom: '20px',

            '& img': {
                maxHeight: '80px',
                maxWidth: '80px',
            },

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                minWidth: '116px',
                '& img': {
                    maxHeight: '33px',
                    width: '66px',
                },
            },
        },
        nameContainer: {
            width: '100%',
            minHeight: '50px',
            marginBottom: '20px',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            fontSize: '22px',
            fontWeight: 'bold',
            borderRadius: '8px',
            color: '#3b454d',
        },

        tabsContainer: {
            width: '100%',
            borderRadius: '0px',
            marginBottom: '20px',
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            flexWrap: 'wrap',
            width: '100%',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'none',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        description: {
            [`@media (min-width:1366px)`]: {
                maxWidth: '300px',
            },
        },
        actionsContainer: {
            minWidth: '230px',
            paddingRight: theme.spacing(2),

            [`@media (max-width:445px)`]: {
                alignItems: 'flex-start',
            },
        },
        backButton: {
            '& >button': {
                '&:hover': {
                    backgroundColor: '#307fc1',
                    color: 'white',
                    '& span': {
                        color: theme.palette.common.white,
                    },
                },
            },
        },
        moreInfoButton: {
            marginTop: theme.spacing(3),
            '& >button': {
                backgroundColor: theme.palette.grey[900],
                color: theme.palette.common.white,

                border: `solid 1px ${theme.palette.grey[900]}`,

                '&:hover': {
                    backgroundColor: theme.palette.grey[900],

                    '& span': {
                        //  color: theme.palette.common.white,
                    },
                },
            },
        },
        actionDropdown: {
            marginTop: '40px',
        },
        showMoreButton: {
            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },

            position: 'absolute',
            bottom: '-15px',
            left: 'calc(50% - 83px)',
            zIndex: 2,
            '& > button': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#ffffff',
                border: 'none',
                height: '32px',
                minWidth: '115px',
                color: '#307fc1',
                fontSize: '11px',
                textTransform: 'uppercase',
                borderRadius: '15px',
                boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                cursor: 'pointer',
                paddingLeft: '12px',
                paddingRight: '6px',
                '& svg': {
                    color: '#307fc1',
                    height: '20px',
                },
            },
        },
        collapsibleContainer: {
            display: 'flex',
            flexDirection: 'column',
            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },
            '& div': {
                textAlign: 'start',
            },
        },
        actionContainerMobile: {},
        azureApplicationDetailsLabel: {
            color: '#1b1f1f',
            textDecoration: 'underline',
        },

        azureApplicationDetailsIdValues: {
            color: '#307fc1',
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                paddingBottom: theme.spacing(2),
            },
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
        commonDeputies: {
            'flex-wrap': 'nowrap',
        },
    }),
)

export const TabsColumn = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 12px 0px 8px',
    marginRight: '20px',
    width: '30%',
    flex: '36%',
})

export const ApplicationBanner = (props: any) => {
    const classes = useStyles()
    const { applicationId } = props
    const { data, isLoading } = useApplication(applicationId)
    const [activeTab, setActiveTab] = useState('overview')
    const query = useQuery()
    const secretId = query.get('secretId')
    const certificateId = query.get('certificateId')
    const history = useHistory()
    const handleTabChange = (tab: any) => {
        setActiveTab(tab)
    }

    return (
        <>
            <Box display="flex">
                <TabsColumn>
                    <Paper className={classes.nameContainer}>
                        <p
                            style={{
                                padding: 10,
                                margin: 0,
                                overflowWrap: 'anywhere',
                            }}
                        >
                            {data?.friendlyName}
                        </p>
                    </Paper>
                    <Paper className={classes.imgContainer}>
                        <img
                            alt={data?.friendlyName}
                            src={
                                isNilOrEmpty(data?.imageUrl)
                                    ? getFullImageUrl(
                                          '/webcdn/Images/AppLogos/Genric-1.png',
                                      )
                                    : getFullImageUrl(
                                          data?.applicationLogoImageUrl,
                                      )
                            }
                        />
                    </Paper>
                    <Paper className={classes.tabsContainer}>
                        <ApplicationTabs
                            active={activeTab}
                            onChange={handleTabChange}
                            application={data}
                        />
                    </Paper>
                    <ActionsNew application={data} />
                </TabsColumn>
                <Paper className={classes.root}>
                    {activeTab === 'overview' && (
                        <Overview applicationId={applicationId} />
                    )}
                    {activeTab === 'secrets' && (
                        <ApplicationSecrets
                            azureApplicationId={data?.azureApplicationId}
                        />
                    )}
                    {activeTab === 'certificates' && (
                        <ApplicationCertificates
                            azureApplicationId={data?.azureApplicationId}
                        />
                    )}
                </Paper>
            </Box>
            {secretId && (
                <SecretsDrawer
                    id={secretId}
                    onClose={() => {
                        query.delete('secretId')
                        history.push(
                            `${history.location.pathname}?${query.toString()}`,
                        )
                    }}
                />
            )}
            {certificateId && (
                <CertificatesDrawer
                    id={certificateId}
                    onClose={() => {
                        query.delete('certificateId')
                        history.push(
                            `${history.location.pathname}?${query.toString()}`,
                        )
                    }}
                />
            )}
        </>
    )
}
