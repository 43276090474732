import { useState } from 'react'
import { DeleteXIcon } from 'packages/eid-icons'
import { makeStyles, createStyles, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useRedStyles = makeStyles(
    createStyles({
        root: {
            position: 'relative',
            height: '31px',
            padding: '0px 15px 0px 10px',
            borderRadius: '16px',

            boxShadow: '0 0 0 0',
            textTransform: 'capitalize',

            backgroundColor: 'transparent',
            border: 'solid 1px #ff4d5f',

            color: '#ff4d5f',
            '&:hover': {
                backgroundColor: '#ff4d5f',
                color: 'white',
                cursor: 'hand',
            },
        },
        dropdownAnchor: {
            position: 'absolute',
            top: '40px',
            left: '115px',
        },
    }),
)

const DeleteButton = ({ item, onClick }) => {
    const { t } = useTranslation()
    const [actionsButtonIconColor, setActionsButtonIconColor] =
        useState('#ff4d5f')

    const buttonClasses = useRedStyles()

    return (
        <Button
            onClick={onClick}
            onMouseEnter={() => {
                setActionsButtonIconColor('white')
            }}
            onMouseLeave={() => {
                setActionsButtonIconColor('#ff4d5f')
            }}
            classes={buttonClasses}
            endIcon={<DeleteXIcon color={actionsButtonIconColor} />}
        >
            {t('Delete')}
        </Button>
    )
}

export default DeleteButton
