import { useQuery } from 'react-query'
import { useAxios, useQuery as useBrowserQuery } from 'packages/core'
import { usePageContext } from 'pageContext'
import useApiWithInfiniteScroll from './useApiWithInfiniteScroll'
export const APPLICATIONS_KEY = 'APPLICATIONS'

export const useApplication = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/applications/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useOwnedApplications = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        startDate,
        endDate,
        sortOrder,
        sortBy,
        status,
        itemType,
        accountStore,
        accountStoreUsageType,
        ownedBy,
        shouldLoadTags,
        advancedSearchTags,
        showOnlyAzureApplications,
    } = state
    const ownerPersonId = ownedBy?.id
    const description = state['advancedSearch.forms.description']
    const friendlyName = state['advancedSearch.forms.friendlyName']

    const callApi = useAxios()

    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (startDate) {
        queryData.startDateUtc = startDate
    }
    if (endDate) {
        queryData.endDateUtc = endDate
    }
    if (accountStoreUsageType) {
        queryData.accountStoreUsageTypeId = accountStoreUsageType.id
    }
    if (accountStore) {
        queryData.accountStoreId = accountStore.id
    }

    if (shouldLoadTags) {
        queryData.includeTags = true
    }

    if (advancedSearchTags) {
        queryData.tags = advancedSearchTags
    }

    const advancedSearch = []
    if (description) {
        advancedSearch.push({
            name: 'Description',
            type: 'string',
            value: description,
        })
    }
    if (friendlyName) {
        advancedSearch.push({
            name: 'FriendlyName',
            type: 'string',
            value: friendlyName,
        })
    }

    queryData.advancedSearch = advancedSearch

    const endpoint = ownerPersonId
        ? `/api/applications/owned/${ownerPersonId}`
        : `api/applications/all`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATIONS',
            searchTerm,
            sortBy,
            sortOrder,
            startDate,
            endDate,
            status,
            itemType,
            description,
            friendlyName,
            ownerPersonId,
            accountStore,
            accountStoreUsageType,
            advancedSearchTags,
            shouldLoadTags,
            showOnlyAzureApplications,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    showOnlyAzureApplications,
                },
            })
        },
    )
}

export const useApplicationOwners = (applicationId: string) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'OWNERS', applicationId],
        () =>
            callApi({
                method: 'GET',
                url: `api/people/resourceOwners?resourceId=${applicationId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(applicationId),
        },
    )
}

export const useAzureApplicationOwnersAndDeputies = (id: string) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'OWNERS_AND_DEPUTIES', id],
        () =>
            callApi({
                method: 'GET',
                url: `api/applications/OwnersDeputies/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationWorkflows = (id?: string) => {
    const callApi = useAxios()
    return useQuery([APPLICATIONS_KEY, 'WORKFLOWS', id], () =>
        callApi({
            method: 'GET',
            url: `api/UIActions/byNoun?noun=ResourceAdmin&verb=Application`,
        }).then((data) => data.data),
    )
}

export const useApplicationCertificates = (azureApplicationId: any) => {
    const callApi = useAxios()

    //TODO: Need to enable this when filters are introduced

    // const [state]: any = usePageContext()
    // const {
    //     searchTerm,
    //     sortOrder,
    //     sortBy,
    //     shouldLoadTags,
    //     advancedSearchTags,
    // } = state
    // const description = state['advancedSearch.forms.description']
    // const friendlyName = state['advancedSearch.forms.friendlyName']

    // const queryData: any = {}

    // if (searchTerm) {
    //     queryData.searchTerm = searchTerm
    // }
    // if (sortBy) {
    //     queryData.sortBy = sortBy
    // }
    // if (sortOrder) {
    //     queryData.desc = sortOrder === 'desc'
    // }

    // if(shouldLoadTags){
    //     queryData.includeTags = true
    // }

    // if(advancedSearchTags){
    // if (shouldLoadTags) {
    //     queryData.includeTags = true
    // }

    // if (advancedSearchTags) {
    //     queryData.tags = advancedSearchTags
    // }

    // const advancedSearch = []
    // if (description) {
    //     advancedSearch.push({
    //         name: 'Description',
    //         type: 'string',
    //         value: description,
    //     })
    // }
    // if (friendlyName) {
    //     advancedSearch.push({
    //         name: 'FriendlyName',
    //         type: 'string',
    //         value: friendlyName,
    //     })
    // }

    // queryData.advancedSearch = advancedSearch

    const endpoint = `/api/certificates/all`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_CERTIFICATES',
            // searchTerm,
            // sortBy,
            // sortOrder,
            // description,
            // friendlyName,
            // advancedSearchTags,
            // shouldLoadTags,
            azureApplicationId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    // ...queryData,
                    skip,
                    take,
                    azureApplicationId,
                },
            })
        },
    )
}

export const useApplicationSecrets = (azureApplicationId: any) => {
    const callApi = useAxios()

    //TODO: Need to enable this when filters are introduced

    // const [state]: any = usePageContext()
    // const {
    //     searchTerm,
    //     sortOrder,
    //     sortBy,
    //     shouldLoadTags,
    //     advancedSearchTags,
    // } = state
    // const description = state['advancedSearch.forms.description']
    // const friendlyName = state['advancedSearch.forms.friendlyName']

    // const queryData: any = {}

    // if (searchTerm) {
    //     queryData.searchTerm = searchTerm
    // }
    // if (sortBy) {
    //     queryData.sortBy = sortBy
    // }
    // if (sortOrder) {
    //     queryData.desc = sortOrder === 'desc'
    // }

    // if(shouldLoadTags){
    //     queryData.includeTags = true
    // }

    // if(advancedSearchTags){
    // if (shouldLoadTags) {
    //     queryData.includeTags = true
    // }

    // if (advancedSearchTags) {
    //     queryData.tags = advancedSearchTags
    // }

    // const advancedSearch = []
    // if (description) {
    //     advancedSearch.push({
    //         name: 'Description',
    //         type: 'string',
    //         value: description,
    //     })
    // }
    // if (friendlyName) {
    //     advancedSearch.push({
    //         name: 'FriendlyName',
    //         type: 'string',
    //         value: friendlyName,
    //     })
    // }

    // queryData.advancedSearch = advancedSearch

    const endpoint = `/api/secrets/all`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_SECRETS',
            // searchTerm,
            // sortBy,
            // sortOrder,
            // description,
            // friendlyName,
            // advancedSearchTags,
            // shouldLoadTags,
            azureApplicationId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    // ...queryData,
                    skip,
                    take,
                    azureApplicationId,
                },
            })
        },
    )
}

export const useApplicationSecret = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'SECRET', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/secrets/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationCertificate = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'CERTIFICATE', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/certificates/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}
