import { useState } from 'react'
import { Box, makeStyles, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
//import Overview from './Overview'
import { useApplicationSettings, useCurrentPerson } from 'hooks'
import { isNilOrEmpty } from 'packages/core'
import { smallScreenWidth } from 'utils'
import { Actions } from './../../components/ApplicationBanner/Actions'
import { OverviewSection } from './OverviewSection'
import { MoreInformationSection } from './MoreInformationSection'

const TabsContainerBox = styled(Box)({
    backgroundColor: '#fbfbfd',
    borderBottom: '1px solid #d8dadd',

    paddingRight: '30px',
    display: 'flex',
    justifyContent: 'space-between',

    '& .Tabs-root': {
        display: 'flex',
    },

    [`@media (max-width:${smallScreenWidth})`]: {
        flexDirection: 'column-reverse',
        paddingTop: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',

        '& .Tabs-root': {
            marginLeft: '-20px',
        },
    },
})

const OverflowContainer = styled(Box)({
    [`@media (max-width:${smallScreenWidth})`]: {
        overflowX: 'auto',
    },
})

const useStyles = makeStyles({
    subHeader: {
        position: 'sticky',
        top: '0px',
        zIndex: 200,
    },
    subHeaderContent: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '7px 20px',
        display: 'flex',
        alignItems: 'center',
    },
})

const ApplicationDetails = ({ data, refetch }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const { data: appConfig } = useApplicationSettings()

    const { data: currentPerson } = useCurrentPerson()

    const [activeTab, setActiveTab] = useState('overview')

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab value="overview" label={t('Overview')} />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('MoreInformation')}
                />
                <ItemDetails.Tabs.Tab value="action" label={t('Actions')} />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && (
                <OverviewSection applicationId={data.id} />
            )}
            {activeTab === 'additionalInformation' && (
                <MoreInformationSection application={data} />
            )}
            {activeTab === 'action' && (
                <Box
                    display="flex"
                    width="100%"
                    height={500}
                    bgcolor="#ffffff"
                    justifyContent="center"
                >
                    <Box paddingTop="30px">
                        <Actions application={data} />
                    </Box>
                </Box>
            )}
        </>
    )
}

export default ApplicationDetails
