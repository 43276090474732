import { Fragment } from 'react'
import { Box, styled } from '@material-ui/core'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import {
    useApplicationOwners,
    useAzureApplicationOwnersAndDeputies,
} from 'hooks'

import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { ItemDetails, PeopleList } from 'components'
import { useComputerOwners } from 'hooks'
import { Skeleton } from '@material-ui/lab'

const PaddedDiv = styled(Box)({
    padding: '0 16px',
})

const SkeletonContainer = styled('div')({
    height: 'max-content',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
})

const AttributeSkeleton = styled(Skeleton)({
    width: '45%',
    margin: '8px',
    height: 6,
})

const Label = styled(AttributeLabel)({
    marginBottom: '10px',
})

export const MoreInformationSection = ({ application }) => {
    const { t } = useTranslation()

    const applicationId = application.isAzureApplication ? '' : application.id
    const azureApplicationId = application.isAzureApplication
        ? application.id
        : ''

    const {
        data: azureApplicationOwnersAndDeputies,
        isLoading: isAzureOwnersLoading,
    } = useAzureApplicationOwnersAndDeputies(azureApplicationId)

    const { data: applicationOwners, isLoading: isApplicationOwnersLoading } =
        useApplicationOwners(applicationId)

    return (
        <Box width="100%" height={500} bgcolor="#ffffff" paddingTop="16px">
            {isAzureOwnersLoading || isApplicationOwnersLoading ? (
                <Attribute>
                    <SkeletonContainer>
                        <AttributeSkeleton animation="wave" variant="rect" />
                    </SkeletonContainer>
                </Attribute>
            ) : (
                <>
                    {!application.isAzureApplication && applicationOwners && (
                        <Attribute>
                            <PaddedDiv>
                                <Label> {t('Owners')}</Label>
                                <PeopleList list={applicationOwners} />
                            </PaddedDiv>
                        </Attribute>
                    )}
                    {application.isAzureApplication &&
                        azureApplicationOwnersAndDeputies && (
                            <>
                                <Box>
                                    <Attribute>
                                        <PaddedDiv>
                                            <Label>{t('Owners')}</Label>
                                            <PeopleList
                                                list={
                                                    azureApplicationOwnersAndDeputies.owners
                                                }
                                            />
                                        </PaddedDiv>
                                    </Attribute>
                                </Box>
                                <Box>
                                    <Attribute>
                                        <PaddedDiv>
                                            <Label>{t('Deputies')}</Label>
                                            <PeopleList
                                                list={
                                                    azureApplicationOwnersAndDeputies.deputies
                                                }
                                            />
                                        </PaddedDiv>
                                    </Attribute>
                                </Box>
                            </>
                        )}
                </>
            )}
        </Box>
    )
}
