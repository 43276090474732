const attributes = [
    {
        hideValue: true,
        component: {
            componentName: 'ApplicationImage',
            name: 'applicationLogoImageUrl',
        },
        styles: {
            width: '70px',
            minWidth: '70px',
            maxWidth: '70px',
        },

        headerCellStyles: {
            width: '100px',
            minWidth: '100px',
            maxWidth: '100px',
        },
    },
    {
        name: 'friendlyName',
        sortable: true,
        sortBy: 'friendlyName',
        label: 'FriendlyName',

        headerCellStyles: {
            width: '200px',
            minWidth: '200px',
            maxWidth: '100px',
        },
        styles: {
            width: '200px',
            maxWidth: '200px',
            color: '#282828',
            fontSize: '13px',
        },
    },

    {
        name: 'name',
        sortable: true,
        sortBy: 'Name',
        label: 'TechnicalName',
        headerCellStyles: {
            width: '200px',
            minWidth: '200px',
            maxWidth: '200px',
        },
        styles: {
            width: '200px',
            minWidth: '200px',
            maxWidth: '200px',
            fontSize: '13px',
        },
    },
    {
        name: 'ownerFriendlyName',
        sortable: true,
        hideValue: true,
        sortBy: 'ownerFriendlyName',
        label: 'Owner',
        component: {
            componentName: 'ApplicationOwner',
        },
        headerCellStyles: {
            width: '170px',
            minWidth: '170px',
            maxWidth: '170px',
            fontSize: '13px',
        },
        styles: {
            minWidth: '170px',
            maxWidth: '170px',
            width: '170px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'description',
        sortable: true,
        sortBy: 'description',
        label: 'Description',

        headerCellStyles: {
            width: '170px',
            minWidth: '170px',
            maxWidth: '170px',
            fontSize: '13px',
        },
        styles: {
            width: '170px',
            minWidth: '170px',
            maxWidth: '170px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        hideValue: true,
        component: {
            componentName: 'actionButtons',
            name: 'applicationLogoImageUrl',
        },
        styles: {
            width: '200px',
            minWidth: '200px',
            maxWidth: '200px',
        },

        headerCellStyles: {
            width: '200px',
            minWidth: '200px',
            maxWidth: '200px',
        },
    },
]

export default attributes
